<template>
  <div class="NoticeDetail">
    <div class="right-SecondTitle">
        <div class="back" @click="back"><span class="icon iconfont">&#xe623;</span>返回上一级</div>
        <div class="line"></div>
    </div>
    <div class="Detail">
      <h2 class="h2">{{msg.messageTitle}}</h2>
      <div class="time">{{msg.createTimeText}}</div>
      <div class="p">
    {{msg.messageContent.content}}
      </div>
    </div>      
  </div>
</template>

<script>
export default {
  name: 'NoticeDetail',
  props:{
    msg:Object
  },
  data () {
    return {
    
    }
  },

  methods: {
      back(){
          this.$emit('set-tab-idx','tab1')
      },
    
  }
}
</script>

<style lang='scss' scoped>
@import '../../../../assets/css/mine.scss';
.NoticeDetail{
  .back{
    cursor: pointer;
    font-size: 14px;
    color: rgba(0, 18, 22, 0.65);
    height: 24px;
    line-height: 24px;
    .icon{opacity: 0.2;line-height: 24px;margin-right: 8px;}
  }
  .Detail{
    .h2{}
    .p{}
    .time{
        color: rgba(0, 18, 22, 0.35);
        font-size: 14px;
        margin: 4px 0 16px 0;
      }
  }
}
</style>
