import request from '@/utils/request'



export function queryNoticeMsg() {
    return request(
        'get',
        '/v1',
       `/cms/custom/notice/message/p/1-100`
    );
}
